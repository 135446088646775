<!--TODO: показывать, только над группой не прочитаных-->
<!--<div class="unread" *ngIf="chatMsg.showDate">Непрочитанные сообщения</div>-->

<div class="date-box"  *ngIf="chatMsg.showDate && !isMsgToday() && !isMsgYesterday()">
    <div class="date">{{chatMsg.createdAt | date: 'd.LL.y'}}
    </div>
</div>

<div class="date-box"  *ngIf="chatMsg.showDate && isMsgToday()">
    <div class="date">Сегодня</div>
</div>

<div class="date-box"  *ngIf="chatMsg.showDate && isMsgYesterday()">
    <div class="date" >Вчера</div>
</div>

<div class="date-box float" *ngIf="!isMsgToday() && !isMsgYesterday()">
    <div class="date">{{chatMsg.createdAt | date: 'd.LL.y'}}
    </div>
</div>

<div class="date-box float" *ngIf="isMsgToday()">
    <div class="date">Сегодня</div>
</div>

<div class="date-box float"  *ngIf="isMsgYesterday()">
    <div class="date" >Вчера</div>
</div>

<li class="msg-frame"
    *ngIf="chatMsg.userId"
    (dblclick)="onReplyClick(chatMsg)"
    [ngClass]="{'_mine': chatMsg.userId === currentUserId,
    '_big-margin': chatMsg.showAvatar || chatMsg.showDate,
    '_avatar': chatMsg.showAvatar,
    '_task': task,
    '_photo': hasAttachment && isImage(),
    '_is-upload': isAttachmentLoader}">

    <span class="answer-btn" *ngIf="chatMsg.userId !== currentUserId && !isEdit"
          [matTooltip]="'Ответить'">
        <span class="answer-btn__inner">
            <mat-icon svgIcon="radius_reply"
                      (click)="onReplyClick(chatMsg)"
                      class="icon"></mat-icon>
        </span>
    </span>

    <app-user-avatar [matMenuTriggerFor]="menu" (menuOpened)="userOpened = true"
                     *ngIf="chatMsg.showAvatar &&  chatMsg.userId !== currentUserId"
                     class="avatar" [user]="chatMsg"></app-user-avatar>
    <mat-menu #menu="matMenu" class="header__settings-menu">
        <app-person-card [userId]="chatMsg.userId" *ngIf="userOpened">
        </app-person-card>
    </mat-menu>

    <div class="user" *ngIf = "chatMsg.showName && chatMsg.userId !== currentUserId">{{chatMsg.lastName}} {{chatMsg.firstName}}</div>

    <div class="msg-top kit" *ngIf="hasAttachment && isImage() && !isAudio()">
        <app-chat-reply *ngIf="chatMsg.reply_to" [reply]="chatMsg.reply_to" class="reply" (click)="onReplyMsgClick()"></app-chat-reply>
        <!--<p class="text" [innerHtml]="chatMsg.message | linkify"></p>-->
        <img class="photo"
             (click)="openImage(chatMsg.attachments[0], $event)"  *ngIf="!isAttachmentLoader" [src]="getImageUrl(chatMsg.attachments[0])">
        <img class="photo" *ngIf="isAttachmentLoader" [ngClass]="{'_uploading': isAttachmentLoader}" [src]="getImageUrl(uploadingFile)">
        <div *ngIf="isAttachmentLoader">
            <div class="blur">
                <div class="cross">
                    <mat-icon class="icon _close _reset" [svgIcon]="'radius_close'" (click)="resetUpload()"></mat-icon>
                    <mat-spinner class="spinner" [diameter]="40" [strokeWidth]="2" [color]="white"></mat-spinner>
                </div>
            </div>
        </div>
        <div class="vert" *ngIf="chatMsg.userId === currentUserId && !isEdit"
             (click)="openImage(chatMsg.attachments[0], $event)">
        </div>
        <div class="menu-block" *ngIf="chatMsg.userId === currentUserId && !isEdit && !isAttachmentLoader">
            <button
                    type="button" (click)="$event.stopPropagation()"
                    class="button _more _icon"
                    [matMenuTriggerFor]="menu">
                <mat-icon class="more">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="more-menu">
                <a
                        *ngIf="chatMsg.message"
                        class="more-menu__link"
                        (click)="changeMessage()"
                >
                    Редактировать
                </a>
                <a
                        class="more-menu__link"
                        (click)="deleteMessage()"
                >
                    Удалить
                </a>
                <a
                        class="more-menu__link"
                        (click)="addToTask()"
                        *ngIf="canAddImage"
                >
                    Добавить в задачу
                </a>
            </mat-menu>
        </div>
    </div>

    <ng-container *ngIf="hasAttachment && !isImage() && !isAudio()">
        <app-chat-reply *ngIf="chatMsg.reply_to" [reply]="chatMsg.reply_to" class="reply" (click)="onReplyMsgClick()"></app-chat-reply>
        <div class="msg-top file-msg kit"
             [ngClass]="{'_downloading': isDownLoading}">
            <mat-icon class="icon _downloading">downloading</mat-icon>

            <a *ngIf="!uploadingFile && isPdf()" class="link file-msg" [href]="isPdf() ? getFileUrlPdf() : null"
               [target]="isPdf() ? '_blank' : null">
                <img class="file__img"
                     [src]="getFileUrl(chatMsg.attachments[0])">
                <span class="txt"
                      [matTooltipClass]="'custom-tooltip-long'"
                      [matTooltip]="chatMsg.attachments[0].fileName">
                    <span class="txt-inner">{{ chatMsg.attachments[0].fileName }}</span>
                </span>
            </a>

            <ng-container *ngIf="!uploadingFile && !isPdf()">
                <img class="file__img"
                     [src]="getFileUrl(chatMsg.attachments[0])" (click)="downloadFile(chatMsg.attachments[0])">
                <span class="txt"
                      [matTooltipClass]="'custom-tooltip-long'"
                      [matTooltip]="chatMsg.attachments[0].fileName" (click)="downloadFile(chatMsg.attachments[0])">
                    <span class="txt-inner">{{ chatMsg.attachments[0].fileName }}</span>
                </span>
            </ng-container>

            <ng-container *ngIf="uploadingFile && !isPdf()">
                <div class="file__img _loader">
                    <div class="cross">
                        <mat-icon class="icon _close _reset" [svgIcon]="'radius_close'" (click)="resetUpload()"></mat-icon>

                        <svg class="spinner" width="50" height="50" viewBox="-6.25 -6.25 62.5 62.5" version="1.1" xmlns="http://www.w3.org/2000/svg" style="transform:rotate(-90deg)">
                            <circle r="15" cx="25" cy="25" stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                    [attr.stroke-dashoffset]="loaderPercent + 'px'" fill="transparent" stroke-dasharray="94px"></circle>
                        </svg>

                    </div>
                </div>
                <span class="txt">{{ uploadingFile.name }}</span>
            </ng-container>
            <div class="vert" *ngIf="chatMsg.userId === currentUserId && !isEdit && !isAttachmentLoader">
                <button
                        type="button"
                        class="button _more _icon"
                        [matMenuTriggerFor]="menu">
                    <mat-icon class="more">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="more-menu">
                    <a
                            class="more-menu__link"
                            (click)="deleteMessage()"
                    >
                        Удалить
                    </a>
                    <a
                            (click)="addToTask()"
                            *ngIf="canAddImage"
                            class="more-menu__link"
                    >
                        Добавить в задачу
                    </a>
                </mat-menu>
            </div>
        </div>
    </ng-container>

    <div *ngIf="chatMsg.userId === currentUserId && !isEdit && isAudio()" class="msg-top kit _audio">
        <div class="vert">
            <button
                    [matMenuTriggerFor]="menu"
                    class="button _more _icon"
                    type="button">
                <mat-icon class="more">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="more-menu">
                <a
                        (click)="deleteMessage()"
                        class="more-menu__link"
                >
                    Удалить
                </a>
            </mat-menu>

        </div>
    </div>

    <app-chat-audio class="audio" [ngClass]="{'_mine': chatMsg.userId === currentUserId}"
                    *ngIf="isAudio()" [src]="getAudioUrl()" [type]="chatMsg.attachments[0].mime"></app-chat-audio>

    <div class="msg-top kit" *ngIf="chatMsg.message || task || !noErrors">
        <app-chat-reply *ngIf="chatMsg.reply_to && !hasAttachment" [reply]="chatMsg.reply_to" class="reply" (click)="onReplyMsgClick()"></app-chat-reply>

        <p class="text" *ngIf="!task && hasTag(chatMsg.message)"
           [innerHtml]="chatMsg.message | linkify
"
           (click)="onClickLink($event, userTag)" [matMenuTriggerFor]="userTag"
           #menuTrigger="matMenuTrigger"></p>

        <p class="text" *ngIf="!task && !hasTag(chatMsg.message)"
           [innerHtml]="chatMsg.message | linkify"></p>

        <mat-menu #userTag="matMenu" class="header__settings-menu" xPosition="before" yPosition="above">
            <app-person-card [userId]="tagUserId" *ngIf="tagUserId">
            </app-person-card>

            <span class="empty-user" *ngIf="tagUserId == undefined">Пользователь не найден</span>
        </mat-menu>

        <app-kanban-item *ngIf="task"
                         class="task"
                         [task]="task"
                         [viewTask]="settingsTasks"
                         [global]="true"
                         (click)="openTask(task)"
                         [embed]="true"
                         [id]="task.id"></app-kanban-item>

        <mat-card appearance="outline" *ngIf="hasTask && !task" class="task-loader">
            <app-spinner class="loader" [isSpinnerOn]="true"></app-spinner>
        </mat-card>

        <ng-container  *ngIf="!noErrors">
            <mat-card appearance="outline" class="task-loader _error">
                <span class="error">404 задача не найдена</span>
            </mat-card>

            <br />

            <p class="text">
                <a class="link" [href]="getTaskLink(chatMsg)">{{getTaskLink(chatMsg)}}</a>
            </p>
        </ng-container>

        <div class="vert" *ngIf="chatMsg.userId === currentUserId && !isEdit && !hasAttachment">
            <button
                    type="button"
                    class="button _more _icon"
                    [matMenuTriggerFor]="menu"
            >
                <mat-icon class="more">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="more-menu">
                <a
                        *ngIf="!isPassedDay() && !hasTask"
                        class="more-menu__link"
                        (click)="changeMessage()"
                >
                    Редактировать
                </a>
                <a
                        class="more-menu__link"
                        (click)="deleteMessage()"
                >
                    Удалить
                </a>
            </mat-menu>
        </div>
    </div>

    <div class="info">
        <span class="edit-mark" *ngIf="chatMsg.edited" [ngClass]="{'_mine': chatMsg.userId === currentUserId}">Изменено</span>
        <span class="time-mark" *ngIf="chatMsg.createdAt && chatMsg.isSent !== false">{{chatMsg.createdAt | date: 'HH:mm'}}</span>
        <mat-icon *ngIf="chatMsg.userId === currentUserId && chatMsg.isSent !== false"
                  [ngClass]="{'_is_read': chatMsg.isRead}"
                  class="send">done_all</mat-icon>
        <mat-icon *ngIf="chatMsg.userId === currentUserId && chatMsg.isSent === false" class="send _spin">sync</mat-icon>
    </div>
</li>

<li class="msg-frame _tech" *ngIf="!chatMsg.userId">
    <p class="text" [innerHtml]="chatMsg.message"></p>
    <div class="info">
        <span class="time-mark" *ngIf="chatMsg.edited">Изменено</span>
        <span class="time-mark">{{chatMsg.createdAt | date: 'HH:mm'}}</span>
    </div>
</li>
