<form class="user" *ngIf="user && isLoaded" [formGroup]="formGroup">
    <header class="user__header">
        <div class="user__header-toolbar _top">
            <app-icon-button class="button _close" type="button" icon="close" (click)="onReset()"></app-icon-button>
        </div>
        
<!--        <app-user-avatar *ngIf="isEmptyAvatar()" class="user__avatar" [user]="user"></app-user-avatar>-->
        <img class="user__avatar" *ngIf="avatar && !isEmptyAvatar()" src="{{ avatar }}">
        <span class="user__avatar" *ngIf="!avatar || isEmptyAvatar()"></span>

        <label class="user__avatar-label" *ngIf="avatar">
            <span class="user__avatar-links" *ngIf="avatar && !isEmptyAvatar()">
                <span class="txt">
                    <input type="file" (change)="onChangeFile($event)" #fileInput
                           class="user__avatar-input">
                    <mat-icon class="icon">add_a_photo</mat-icon>Изменить фото
                </span>
                <span class="txt" (click)="deleteAvatar($event)">
                    <mat-icon class="icon">delete_outline</mat-icon>Удалить фото
                </span>
            </span>

            <span class="user__avatar-links" *ngIf="!avatar || isEmptyAvatar()">
                <span class="txt">
                    <input type="file" (change)="onChangeFile($event)" #fileInput
                           class="user__avatar-input">
                    <mat-icon class="icon">add_a_photo</mat-icon>Добавить фото
                </span>
            </span>
        </label>

        <label class="user__avatar-label _empty" *ngIf="!avatar">
            <span class="user__avatar-links">
                <span class="txt">
                    <input type="file" (change)="onChangeFile($event)" #fileInput
                           class="user__avatar-input">
                    <mat-icon class="icon">add_a_photo</mat-icon>Загрузить фото
                </span>
            </span>
        </label>
    </header>

    <div class="user__content">
        <section class="user__section" *ngIf="user.id">
            <app-status-select-tpl
                [formGroup]="formGroup"
            ></app-status-select-tpl>
        </section>

        <mat-divider></mat-divider>

        <section class="user__section">

            <h2 class="user__content-title _edit-card">Личные данные</h2>

            <label class="label kit _assessment">
                    <span class="label__txt">
                        * Фамилия
                    </span>
                <input class="input"
                       formControlName="lastName"
                       [pattern]="NAME_REGEXP"
                       [ngClass]="{'_error': hasError(formGroup.controls['lastName'])}"
                       autocomplete="off">
                <mat-error class="error"  *ngIf="hasError(formGroup.controls['lastName'])">{{getErrorMsg('lastName')}}</mat-error>
            </label>

            <label class="label kit _assessment">
                    <span class="label__txt">
                        * Имя
                    </span>
                <input class="input"
                       formControlName="firstName"
                       [pattern]="NAME_REGEXP"
                       [ngClass]="{'_error': hasError(formGroup.controls['firstName'])}"
                       autocomplete="off">
                <mat-error class="error"  *ngIf="hasError(formGroup.controls['firstName'])">{{getErrorMsg('firstName')}}</mat-error>

            </label>

            <label class="label kit _assessment">
                    <span class="label__txt">
                        Отчество
                    </span>
                <input class="input"
                       [pattern]="NAME_REGEXP"
                       [ngClass]="{'_error': hasError(formGroup.controls['middleName'])}"
                       formControlName="middleName"
                       autocomplete="off">
                <mat-error class="error"  *ngIf="hasError(formGroup.controls['middleName'])">{{getErrorMsg('middleName')}}</mat-error>

            </label>

            <label class="label kit _date" *ngIf="user.id">
                    <span class="label__txt">
                        Дата рождения
                    </span>
                <input class="input"
                       #birthdayElem
                       placeholder="01.12.1990"
                       formControlName="birthday"
                       autocomplete="off"

                       [matDatepicker]="birthdayPicker">

                <mat-datepicker-toggle matSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
                <mat-datepicker #birthdayPicker></mat-datepicker>
            </label>
        </section>
        <section class="user__section" *ngIf="user.id">
            <h2 class="user__content-title _edit-card">
                Подразделения и должности
            </h2>
            <div class="user__contacts user__slots" *ngIf="!user.isBlocked">
                <div class="slots-container"   *ngIf="formGroup.controls['slots'].value">
                    <ng-container *ngFor="let slot of formGroup.controls['slots'].value">
                        <app-personal-position-slot class="slot"
                                                    [canOpen]="!isAboutCompany"
                                                    [slot]="slot"
                                                    [isEdit]="true"
                                                    (change)="changeSubdivision($event)"
                                                    [isAdmin]="rights.isAdministrator"
                                                    [ngClass]="{'position': !slot.isRole, '_disabled': isAboutCompany}"
                        ></app-personal-position-slot>
                    </ng-container>
                </div>
                <app-personal-position-slot class="slot added"
                                            [isEdit]="true"
                                            [canOpen]="!isAboutCompany"
                                            [isAdmin]="rights.isAdministrator"
                                            [added]="true"
                                            [user]="user"
                                            (createSlot)="addSubdivision($event)"
                ></app-personal-position-slot>
            </div>
        </section>
        <section class="user__section" *ngIf="user.id">
            <h2 class="user__content-title _edit-card">
                <mat-icon class="icon" svgIcon="radius_mobile"></mat-icon>
                Телефон
            </h2>
            <app-phones [data]="user.phones"
                        [mobile]="formGroup.controls['mobilePhone'].value"
                        [internal]="formGroup.controls['internalPhone'].value"
                        [work]="formGroup.controls['workPhone'].value"
                        (changePhones)="onBlockChange('phones', $event)"
                        (changeInternalPhone)="onBlockChange('internalPhone', $event)"
                        (changeWorkPhone)="onBlockChange('workPhone', $event)"
                        (changeMobile)="onBlockChange('mobile', $event)"
                        [setUserId]=user.id></app-phones>
        </section>

        <section class="user__section">
            <h2 class="user__content-title _edit-card">
                <mat-icon class="icon" svgIcon="radius_mail"></mat-icon>
                Почта
            </h2>
            <app-accounts [data]="user.accounts" *ngIf="user.id" [setUserId]=user.id
                          (onChange)="onBlockChange('accounts', $event)"
                          (onLoaded)="onAccountsCheck($event)"></app-accounts>

            <label class="label kit" *ngIf="!user.id">
                <div class="label-box">
                    <span class="label__txt">
                       Email
                    </span>
                </div>


                <input class="input"
                       [ngClass]="{'_error': hasError(formGroup.controls['email'])}"
                       formControlName="email"
                       [placeholder]="'example@mail.ru'">
                <mat-error class="error"
                           *ngIf="hasError(formGroup.controls['email'])">
                    Некорректный емейл
                </mat-error>
            </label>
        </section>
        <section class="user__section" *ngIf="user.id">
            <h2 class="user__content-title _edit-card">
                <mat-icon class="icon" svgIcon="radius_mobile"></mat-icon>
                Мессенджер
            </h2>
            <app-messengers [data]="user.messenger" (onChange)="onBlockChange('messenger', $event)"></app-messengers>
        </section>

        <section class="user__section" *ngIf="user.id">
            <h2 class="user__content-title _edit-card" *ngIf="user.id">Рабочие данные</h2>

            <label class="label kit _assessment">
            <span class="label__txt">
               Рабочее место
            </span>
                <input class="input"
                       formControlName="location"
                       autocomplete="off">
            </label>
            <label class="label kit _assessment">
                <span class="label__txt">
                    Доп. информация
                </span>
                <textarea class="input _info"
                          cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize"
                          cdkAutosizeMinRows="0.3"
                          cdkAutosizeMaxRows="3"
                          formControlName="additionalInfo"
                          [ngClass]="{'_error': hasError(formGroup.controls['additionalInfo'])}"
                          autocomplete="off"></textarea>
            </label>
        </section>
    </div>
    <footer class="user__footer">
        <app-button class="button _reset"
                    buttonType="outlined"
                    title="Отмена"
                    (click)="onReset()"
                    [type]="'reset'"
                    type="reset"></app-button>
        <app-button class="button"
                    title="Сохранить"
                    (click)="onSubmit()"
                    [disabled]="isButtonsDisabled || !isEmailValid(user.mobilePhone?.phone)"
                    [type]="'submit'"></app-button>
    </footer>
</form>
