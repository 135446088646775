import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {PhonebookService} from './phonebook.service';
import {ConfigPhonebookService} from './config-phonebook.service';
import {FunctionsService} from './functions.service';
import {MatrixService} from './matrix.service';
import {CompanyService} from './company.service';
import {AuthService} from '../../../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class NavService {
    private history: string[] = [];

    constructor(
        private functions: FunctionsService,
        private phonebook: PhonebookService,
        private companyService: CompanyService,
        private router: Router,
        private config: ConfigPhonebookService,
        private matrix: MatrixService,
        private auth: AuthService,
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.history.push(event.urlAfterRedirects);
            }
        });
    }

    closeRole() {
        const newUrl = this.router.url.slice(0, this.router.url.indexOf('role/'));
        this.open(newUrl);
    }

    closeSlot() {
        const newUrl = this.router.url.slice(0, this.router.url.indexOf('slot/'));
        this.open(newUrl);
    }

    closeUser() {
        const newUrl = this.router.url.slice(0, this.router.url.indexOf('user/'));
        this.open(newUrl);
    }

    openFrdBySlotId(id) {
        this.open(this.config.POSITIONS_URL + '/frd/' + id);
    }

    openPositionByIdInFrd(id) {
        this.open(this.config.POSITIONS_URL + '/frd/' + id);
    }

    openNewUserForm() {
        this.open(this.config.NEW_USER_URL);
    }

    openNewUsersForm() {
        this.open(this.config.NEW_USERS_URL);
    }

    openSubdivisionById(id) {
        this.open(this.config.SUBDIVISIONS_URL + '/subdivision/' + id);
    }

    openTreeSubdivisionById(id) {
        this.open(this.config.SUBDIVISIONS_TREE_URL + '/subdivision/' + id);
    }

    openPositionById(id) {
        this.open(this.config.POSITIONS_URL + '/position/' + id);
    }

    openFolderPositionById(id) {
        this.open(this.config.POSITIONS_URL + '/folder/' + id);
    }

    openNewSubdivisionForm() {
        this.open(this.config.SUBDIVISIONS_URL + '/subdivision/new/');
    }

    openNewPositionForm() {
        this.open(this.config.POSITIONS_URL + '/position/new/');
    }

    openNewFolderPositionForm() {
        this.open(this.config.POSITIONS_URL + '/folder/new/');
    }

    openUserMotivCardById(userId, empId) {
        this.open(this.config.MOTIVATIONS_URL + 'timetable/' + userId + '/' + empId);
    }

    openMotivationById(userId, empId, motivationId) {
        this.open(this.config.MOTIVATIONS_URL + 'timetable/' + userId.toString() +
            '/' + empId.toString() +
            '/' + motivationId.toString());
    }

    openApproveMotivationById(id) {
        this.open(this.config.MOTIVATIONS_URL + 'approve-time-table/' + id);
    }

    noop() { }

    openMatrixById(empId, matrixId, dateFrom) {
        this.matrix.scrolled$.next(true);

        this.router.navigate( [this.config.MOTIVATIONS_URL + 'matrix/' + matrixId]);
    }

    openMatrixByCabinet(emp) {
        const empId = emp.id;
        this.open(this.config.MATRIX_CABINET + '/cabinet-matrix/' + this.auth.auth.id + '/' + empId);
    }

    openMatrixByIdCabinet(id) {
        const matrixId = id;
        this.open(this.config.MATRIX_CABINET + '/cabinet-matrix/' + matrixId);
    }

    closeMotivation() {
        const str = this.router.url;
        const index = str.lastIndexOf('/');
        this.open(str.slice(0, index));
    }

    closeSubdivision() {
        const tree = this.router.url.indexOf('tree');
        if (tree > 0) {
            this.open(this.config.SUBDIVISIONS_TREE_URL);
            return false;
        }
        this.open(this.config.SUBDIVISIONS_URL);
    }

    closePosition() {
        const question = this.router.url.indexOf('frd');
        if (question > 0) {
            const newUrl = this.router.url.slice(0, this.router.url.indexOf('frd/'));
            this.open(newUrl);
        }
        this.open(this.config.POSITIONS_URL);
    }

    closeFrdCard() {
        this.open(this.config.POSITIONS_URL);
    }

    openRoleById(id) {
        const isCompanyRole = this.router.url.indexOf('role');
        if (isCompanyRole > 0) {
            const newUrl = this.router.url.slice(0, this.router.url.indexOf('role/'));
            this.open(newUrl + /role/ + id);
        } else {
            this.open(this.router.url + /role/ + id);

        }
    }

    openSlotById(id) {
        const opened = this.subdivisionOpenedCheck();

        if (opened) {
            const newUrl = this.router.url.slice(0, this.router.url.indexOf(opened));
            this.open(newUrl + /slot/ + id);
        } else {
            this.open(this.router.url + /slot/ + id);
        }

    }

    openFunctionById(id) {
        const opened = this.subdivisionOpenedCheck();

        if (opened) {
            const newUrl = this.router.url.slice(0, this.router.url.indexOf(opened));
            this.open(newUrl + /function/ + id);
        } else {
            this.open(this.router.url + /function/ + id);
        }
    }

    openUserById(id) {
        const isCompany = this.router.url.indexOf('company');
        const isCompanyUser = this.router.url.indexOf('user');

        if (isCompany > 0) {
            if (isCompanyUser > 0) {
                const newUrl = this.router.url.slice(0, this.router.url.indexOf('user/'));
                this.open(newUrl + /user/ + id);
            } else {
                this.open(this.router.url + /user/ + id);

            }
        } else {
            this.open(this.config.USER_URL + id);
        }

    }

    functionsOpenedCheck() {
        const delegates = this.router.url.indexOf('delegates');
        if (delegates > 0) {
            return 'delegates';
        }
        const customers = this.router.url.indexOf('customers');
        if (customers > 0) {
            return 'customers';
        }
        const performers = this.router.url.indexOf('performers');
        if (performers > 0) {
            return 'performers';
        }
        const chat = this.router.url.indexOf('chat');
        if (chat > 0) {
            return 'chat';
        }
        const question = this.router.url.indexOf('question');
        if (question > 0) {
            return 'question';
        }

        const satisfaction = this.router.url.indexOf('satisfaction');
        if (satisfaction > 0) {
            return 'satisfaction';
        }

        return null;
    }

    subdivisionOpenedCheck() {
        const func = this.router.url.indexOf('function');
        if (func > 0) {
            return 'function';
        }
        const slot = this.router.url.indexOf('slot');
        if (slot > 0) {
            return 'slot';
        }
        const satisfaction = this.router.url.indexOf('satisfaction');
        if (satisfaction > 0) {
            return 'satisfaction';
        }

        return null;
    }

    openDelegates() {
        const opened = this.functionsOpenedCheck();

        if (opened) {
            const newUrl = this.router.url.slice(0, this.router.url.indexOf(opened));
            this.open(newUrl + /delegates/);
        } else {
            this.openNewPath(/delegates/);
        }
    }

    openCustomers() {
        const opened = this.functionsOpenedCheck();

        if (opened) {
            const newUrl = this.router.url.slice(0, this.router.url.indexOf(opened));
            this.open(newUrl + /customers/);
        } else {
            this.openNewPath(/customers/);
        }
    }

    openFunctionsChat() {
        const opened = this.functionsOpenedCheck();

        if (opened) {
            const newUrl = this.router.url.slice(0, this.router.url.indexOf(opened));
            this.open(newUrl + /chat/);
        } else {
            this.openNewPath(/chat/);
        }
    }

    openSubdivisionSatisfaction() {
        const opened = this.subdivisionOpenedCheck();

        if (opened) {
            const newUrl = this.router.url.slice(0, this.router.url.indexOf(opened));
            this.open(newUrl + /satisfaction/);
        } else {
            this.open(this.router.url + /satisfaction/);
        }
    }

    openFunctionSatisfaction() {
        const opened = this.functionsOpenedCheck();

        if (opened) {
            const newUrl = this.router.url.slice(0, this.router.url.indexOf(opened));
            this.open(newUrl + /satisfaction/);
        } else {
            this.openNewPath(/satisfaction/);
        }
    }

    openPerformers() {
        const opened = this.functionsOpenedCheck();

        if (opened) {
            const newUrl = this.router.url.slice(0, this.router.url.indexOf(opened));
            this.open(newUrl + /performers/);
        } else {
            this.openNewPath(/performers/);
        }
    }

    openQuestionById(id) {
        const opened = this.functionsOpenedCheck();

        if (opened) {
            const newUrl = this.router.url.slice(0, this.router.url.indexOf(opened));
            this.open(newUrl + /question/ + id);
        } else {
            this.openNewPath(/question/ + id);
        }
    }

    closeFunctionSatisfaction() {
        const question = this.router.url.indexOf('satisfaction');
        if (question > 0) {
            const newUrl = this.router.url.slice(0, this.router.url.indexOf('satisfaction/'));
            this.open(newUrl);
        }
    }

    closeFunctionQuestion() {
        const question = this.router.url.indexOf('question');
        if (question > 0) {
            const newUrl = this.router.url.slice(0, this.router.url.indexOf('question/'));
            this.open(newUrl);
        }
    }

    closeFunctionSubCard() {
        const opened = this.functionsOpenedCheck();

        if (opened) {
            const newUrl = this.router.url.slice(0, this.router.url.indexOf(opened));
            this.open(newUrl);
        }
    }

    openNewPath(path) {
        if (this.functions.chatRoomsFlag$.value) {
            const functionId = this.companyService.openedFunctionId$.value;
            const fullPath = 'apps/phonebook/company/functions/function/' + functionId + path;
            this.open(fullPath);
            return false;
        }
        this.open(this.router.url + path);
    }

    openAdminCompany(id) {
        this.open(this.config.ABOUT_COMPANY + /user/ + id);
    }

    closeAdminCompany() {
        this.open(this.config.ABOUT_COMPANY);
    }

    openEditUserById(id) {
        this.open(this.router.url + '/edit/');
    }

    openMatrixTasks(type: string, route) {

        if (!type) {
            this.router.navigate(['add-tasks/'], {relativeTo: route, queryParamsHandling: 'merge'})

        } else {
            this.router.navigate(['add-tasks-additional'], {relativeTo: route, queryParamsHandling: 'merge'})
        }

        // let newUrl = this.router.parseUrl(urlTree.toString() + '/tasks/matrix-tasks');
        // newUrl.queryParams = {type};
        //
        // console.log(newUrl.toString());
        //this.open(newUrl.toString());
    }

    //открытие задачи из карточки. нужен vid
    viewMatrixTask(taskId, route) {
        if (!taskId) {
            return;
        }
        this.router.navigate(['view-task/'+taskId], {relativeTo: route, queryParamsHandling: 'merge'});
    }

    openMatrixTaskById(taskId, route) {

        if (taskId) {
            this.router.navigate([taskId], {relativeTo: route, queryParamsHandling: 'merge'});
        } else {
            this.router.navigate(['new'], {relativeTo: route, queryParamsHandling: 'merge'});
        }
    }

    changeMatrixTaskById(userId, taskId, motivationId, dateStart) {
        taskId ? this.open(this.config.MOTIVATIONS_URL + 'matrix/' + userId + '/' +  motivationId+ '/tasks/matrix-tasks/matrix-task/' + taskId) :
            this.open(this.config.MOTIVATIONS_URL + 'matrix/' + userId + '/'  + motivationId + '/tasks/matrix-tasks/matrix-task/new');
    }

    changeMatrixCabinetTaskById(userId, taskId, motivationId, dateStart) {
        taskId ? this.open(this.config.MATRIX_CABINET + '/cabinet-matrix/'  + userId + '/' +  motivationId+  '/tasks/matrix-tasks/matrix-task/' + taskId) :
            this.open(this.config.MATRIX_CABINET + '/cabinet-matrix/' + userId + '/' +  motivationId+  '/tasks/matrix-tasks/matrix-task/new');
    }

    redirectCabinet() {
        this.open(this.config.ABOUT_ME);
    }

    open(url, query?) {
        // if (this.functions.chatRoomsFlag$.value) {
        //     console.log(url)
        //     this.functions.chatRoomsFlag$.next(false)
        //     this.functions.openFunction$.next(true);
        //     return false;
        // }
        //

        if (url) {
            this.router.navigate([url], {queryParams: query});
        }
    }

    openNewFunctionForm() {
        this.open(this.config.FUNCTIONS_URL + 'function/new/');
    }

    openNewFunctionById(id) {
        this.open(this.config.FUNCTIONS_URL + '/function/' + id);
    }

    closeFunction() {
        const newUrl = this.router.url.slice(0, this.router.url.indexOf('function/'));
        this.open(newUrl);
    }

    openTaskInCalById(id) {
        this.open(this.config.CALENDAR_URL + '/tasks/' + id);
    }

    openTaskInDashById(id) {
        this.open(this.config.DASHBOARD_URL + '/tasks/' + id);
    }
}
